import axios from '../httpClient';

const listFundsControlAccounts = (status = [],page_number=1) => {
  let link = `/funds-control-accounts`;
  if (status && status.length > 0) {
    link += `?statuses=${status.join(',')}`;
  }
  link += `&page_size=10&page_number=${page_number}`
  return axios.get(link);
}

const showFundsControlAccount = (id) => {
  let link = `/funds-control-accounts`
  if (id) {
    link += `/${id}`;
  }
  return axios.get(link);
};
const searchFundsControlAccount = (statuses = [], keyword, signal = {}, partyId = null, page_number = 1,filters=[]) => {
  let link = `/funds-control-accounts?search=${keyword}`;
  if (statuses && statuses.length > 0) {
    link += `&statuses=${statuses.join(',')}`;
  }
  if (filters && filters.length > 0) {
    link += `&service_types=${filters.join(',')}`;
  }

  if (partyId) {
    link += `&party_id=${partyId}`;
  }
  link += `&page_size=10&page_number=${page_number}`

  return axios.get(link, signal);
}

const storeFundsControlAccount = (data) => axios.post('/funds-control-accounts', data);

const updateFundsControlAccount = (id, data) => axios.put(`/funds-control-accounts/${id}`, data);

const deleteFundsControlAccount = (id) => axios.delete(`/funds-control-accounts/${id}`);

export { deleteFundsControlAccount, listFundsControlAccounts, searchFundsControlAccount, showFundsControlAccount, storeFundsControlAccount, updateFundsControlAccount };
