import {
  FETCH_PERMISSION,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_FAILURE,
} from './permissionTypes'

const initialState = {
  loading: false,
  status: '',
  error: '',
  permissions: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERMISSION:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PERMISSION_SUCCESS:
      return {
        loading: false,
        permissions: action.payload,
        error: '',
      }
    case FETCH_PERMISSION_FAILURE:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default reducer
