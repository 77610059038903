import axios from '../httpClient';

const listBankAccounts = () => axios.get('/bank-accounts');

const showBankAccount = (id) => axios.get(`/bank-accounts/${id}`);
const searchBankAccount = (keyword,signal) => {
    let link = `/bank-accounts?search=${keyword}`;

    return axios.get(link,signal);
}

const storeBankAccount = (data) => axios.post('/bank-accounts', data);

const updateBankAccount = (id, data) => axios.put(`/bank-accounts/${id}`, data);

const deleteBankAccount = (id) => axios.delete(`/bank-accounts/${id}`);

export { listBankAccounts, storeBankAccount, updateBankAccount, searchBankAccount, showBankAccount, deleteBankAccount };
