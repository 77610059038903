import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { fetchPermission } from 'src/redux';
import { getUser } from 'src/services/UserService';
import { handleApiErrors } from 'src/utils/HandleErrors';

function CallApis() {
  const [user, setuser] = useState({})
  const dispatch = useDispatch();

  const fetchPermissionsList = async () => {
    dispatch(fetchPermission());
  };

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response.status === 200) {
        setuser(response.data)
      }
    } catch (error) {
      if (window.location.pathname !== '/login') {
        handleApiErrors(error);
      }
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    getUserDetails();
    fetchPermissionsList();
  }, [])
  return (
    <></>
  )
}

export default CallApis
