import axios from 'axios'
import {
  FETCH_PERMISSION,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_FAILURE,
} from './permissionTypes'
import { toast } from 'react-toastify'
import { listBankAccounts } from 'src/services/BankAccountService'
import { listDrawRequests } from 'src/services/DrawRequestService'
import { getPermissions } from 'src/services/PermissionService'

export const fetchPermission = () => {
  return (dispatch) => {
    dispatch(fetchPermissions())
      getPermissions()
      .then((response) => {
        // response.data contains the permissions
        dispatch(fetchPermissionsSuccess(response.data.permissions));
      })
      .catch((error) => {
        // error.message contains the error message
        dispatch(fetchPermissionsFailure(error.response.data));
      });
  }
}

export const fetchPermissions = () => {
  return {
    type: FETCH_PERMISSION,
  }
}

export const fetchPermissionsSuccess = (permissions) => {
  return {
    type: FETCH_PERMISSION_SUCCESS,
    payload: permissions,
  }
}

export const fetchPermissionsFailure = (error) => {
  return {
    type: FETCH_PERMISSION_FAILURE,
    payload: error,
  }
}
