// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "push-notification-1cbf8.firebaseapp.com",
  projectId: "push-notification-1cbf8",
  storageBucket: "push-notification-1cbf8.appspot.com",
  messagingSenderId: "805233728061",
  appId: "1:805233728061:web:7203ecb120184b74c66854",
  measurementId: "G-3V3PPS1S04"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission()
  if(permission === "granted") {
    console.log("first")
    const token = await getToken(messaging,{
      vapidKey: "BPkyBeHARI5tmzlfv3fLsjPtmBNuzRenJBtSe7k9li2f5VptcjFA3MXACK0TYsKSolB8sqoR7rCsdlgqKtXOIa8"
    })
  }
}
