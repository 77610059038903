import axios from "axios";
import { API_URL } from "./config";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

axios.defaults.headers.common = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

axios.defaults.trailingSlash = false;
axios.defaults.withCredentials = true;

let axiosInst = axios.create({
    headers: {

    },
    baseURL: API_URL,
    cancelToken: source.token,

    onDownloadProgress: function (progressEvent) {
        if (progressEvent.lengthComputable) {
            // var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

        }

    },
    onUploadProgress: function (progressEvent) {
        if (progressEvent.lengthComputable) {
            // var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

        }
    }
});

axiosInst.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        // config.headers.Authorization = 'Bearer ' + sessionStorage.getItem("_token");

        if (config.data && !(config.data instanceof FormData)) {
            let data = config.data;
            let newData = {};
            // Map through each request parameter and remove any object being added to the request payload
            Object.keys(data).forEach(f => {
                if (data[f] == null || typeof (data[f]) !== "object" || data[f] instanceof File || data[f] instanceof FileList) {
                    newData[f] = data[f];
                }
            });
            // config.data = newData;
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInst.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {

    // console.log(error.response.status, error.response.statusText, error.request.responseURL, error.response.data);
    switch (error.response.status) {
        case 400:
          if (error.response.data?.error_code === "MFA_REQUIRED") {
            console.log("You must enable Two-Factor Authentication before you can access this resource.");
            window.location.pathname = '/profile';
          }
        break;
        case 401:
          const currentPath = window.location.pathname;
          const match = currentPath.match(/^\/funds-control-account\/\d+$/);

          if (match) {
              localStorage.setItem('attemptedURL', currentPath);
          }
            if (window.location.pathname !== '/login') {
                console.log("Error!", "Not logged in. We are redirecting you to the login page. You may login with an account and try the operation again.");
                window.location.pathname = '/login';
            }
            break;
        case 403:
            console.log("Error!", "You tried to perform an operation you are not authorized of. See console for more information.", "info");
            break;
        case 404:
            console.log("Error!", "You asked for a resource from server which does not exist.", "info");
            break;
        case 405:
            console.log("Error!", "Invalid method tried for a route. See console for more information.", "info");
            break;
        case 422:
            console.log("Error!", "Unprocessible request.\n" + error.response.data.message, "warning");
            break;
        case 500:
            console.log("Error!", "Something bad happened on server when we sent a request to it. Please contact our support for technical assistance and immediately report this matter.\n" + error.response.data.message, "error");
            break;
        case 503:
            console.log("Under Maintenance", "It seems that the server is not ready to handle this request. Please wait for a while and try your operation again.\n" + error.response.data.message, "warning");
            break;
        default:
            break;
    }

    return Promise.reject(error);
});

export default axiosInst;
