import { combineReducers } from "redux";
import sidebar from "./sidebar";
import permission from './permission/permissionReducer'

const rootReducer = combineReducers({
  sidebar: sidebar,
  permission: permission,
});

export default rootReducer;
