import {
  cilBell,
  cilLockLocked,
  cilUser
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImage
} from '@coreui/react'
import { useEffect, useState } from 'react'

import { getUser, logoutUser } from 'src/services/UserService'
import { handleApiErrors } from 'src/utils/HandleErrors'
import avatar9 from './../../assets/images/avatars/avatar9.png'
import arrowDown from './../../assets/images/dashboard/arrow-down.png'

const AppHeaderDropdown = () => {
  const [user, setuser] = useState({})

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response.status == 200) {
        setuser(response.data)
      }
    } catch (error) {
      handleApiErrors(error)
      console.error('Error:', error);
    }
  }

  // useEffect(() => {
  //   getUserDetails()
  // }, [])

  const handleSubmit = async (e) => {
    try {
      const response = await logoutUser();
      if (response.status == 204) {
        window.location = '/login'
      }
    } catch (error) {
      handleApiErrors(error)
      console.error('Error:', error);
    }
  }
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <div className='d-flex align-items-center'>
          <CAvatar src={avatar9} size='lg' style={{ width: '2.1rem', margin: '0 1rem' }} />
          <span style={{ fontWeight: '600', fontSize: '18px', color: '#32323B' }}>{user?.name}</span>
          <CImage src={arrowDown} className='ms-2' />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownHeader className="bg-light fw-semibold py-2">{user?.name}</CDropdownHeader> */}

         <CDropdownItem href="/profile">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        {/*
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownDivider />
        <CDropdownItem onClick={() => handleSubmit()}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
