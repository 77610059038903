import { toast } from "react-toastify";

export const handleApiErrors = (error) => {
  if (error.response && error.response.data) {
    const { message, errors } = error.response.data;

    // Display the message
    console.error('API Error Message:', message);

    // Display errors for each field
    for (const field in errors) {
       // Displaying the first error for each field
      toast.error(`${errors[field][0]}`)
    }
  } else {
    console.error('An error occurred:', error.message);
  }
};