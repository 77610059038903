import {
  cilBank,
  cilBarChart,
  cilBook,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCamera,
  cilClipboard,
  cilDescription,
  cilGroup,
  cilIndustry,
  cilList,
  cilMenu,
  cilMobileLandscape,
  cilPlus,
  cilSpeedometer,
  cilSpreadsheet,
  cilUser,
  cilWallet,
  cilZoom
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CNavGroup, CNavItem } from '@coreui/react'
import { ReactComponent as Fca } from 'src/assets/images/fca/sidebar/fca.svg'
import { ReactComponent as Reports } from 'src/assets/images/fca/sidebar/reports.svg'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Profile',
  //   to: '/profile',
  //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  // },
  {
    component: CNavGroup,
    name: 'Projects',
    icon: <Fca className='ms-2 me-3' stroke="#025346" />,
    items: [
      {
        icon: <CIcon icon={cilList} customClassName="nav-icon" stroke="#025346" />,
        component: CNavItem,
        name: 'Fund Control',
        to: '/funds-control-account',
      },
      {
        icon: <CIcon icon={cilCamera} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Inspections Only',
        to: '/fca/inspection',
      },
    ]
  },
  {
    component: CNavItem,
    name: 'Draw Request',
    to: '/draw-request',
    icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Reports',
    icon: <Reports className='ms-2 me-3' stroke="#025346" />,
    items: [
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Construction Cost',
        to: '/report/construction-cost'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Hard Cost Budget',
        to: '/report/hard-cost-budget'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Portfolio Recap',
        to: '/report/portfolio-recap'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Disbursements',
        to: '/report/bank-check'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Deposits',
        to: '/report/receipt'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Bank Account',
        to: '/report/bank-account'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Inspection Details',
        to: '/report/inspection-detail'
      },
      {
        icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'FC Balance Sheet',
        to: '/report/fca-balance-sheet',
      },
    ]
  },
  // {
  //   component: CNavItem,
  //   name: 'Inspections',
  //   to: '/inspection',
  //   icon: <CIcon icon={cilCamera} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'FC Form',
  //   to: '/fca/create',
  //   icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'PCR Form',
  //   to: '/pcr/create',
  //   icon: <CIcon icon={cilMenu} customClassName="nav-icon" />,
  // },
]

export default _nav
