import axios from '../httpClient';

const listUsers = () => axios.get('/users');

const showUser = (id) => axios.get(`/users/${id}`);
const searchUser = (keyword) => {
    let link = `/users?search=${keyword}`;

    return axios.get(link);
}

const storeUser = (data) => axios.post('/users', data);

const updateUser = (id, data) => axios.put(`/users/${id}`, data);

const deleteUser = (id) => axios.delete(`/users/${id}`);

const logoutUser = () => axios.post(`/logout`);

const getUser = () => axios.get(`/get-user`);

export { listUsers, storeUser, updateUser, searchUser, showUser, deleteUser, logoutUser, getUser };