import axios from '../httpClient';

const listDrawRequests = (status = []) => axios.get('/draw-requests?statuses=' + status.join(','));

const showDrawRequest = (id) => axios.get(`/draw-requests/${id}`);

const searchDrawRequest = (statuses = [], keyword, signal = {}, fundControlAccountId = null) => {
  let link = `/draw-requests?search=${keyword}`;

  if (fundControlAccountId) {
    link += `&fund_control_account_id=${fundControlAccountId}`;
  }

  if (statuses.length) {
    link += `&statuses=${statuses.join(',')}`;
  }

  return axios.get(link, signal);
}

const storeDrawRequest = (data) => axios.post('/draw-requests', data);

const drawRequestDocs = (id) => axios.get(`/draw-requests/${id}/documents/assess`);

const updateDrawRequest = (id, data) => axios.put(`/draw-requests/${id}`, data);

const deleteDrawRequest = (id) => axios.delete(`/draw-requests/${id}`);

const notifyDrawRequest = (id) => axios.post(`/draw-requests/${id}/notify`);

const signDrawRequest = (id) => axios.post(`/draw-requests/${id}/sign`);

const assessDrawRequest = (data) => axios.post('/draw-requests/assess', data);

export { assessDrawRequest, deleteDrawRequest, drawRequestDocs, listDrawRequests, notifyDrawRequest, searchDrawRequest, showDrawRequest, signDrawRequest, storeDrawRequest, updateDrawRequest };

