import React from 'react'
import { CFooter } from '@coreui/react'
import { Link } from 'react-router-dom'

const AppFooter = () => {
  return (
    <CFooter className="mt-auto">
    <div className="mx-auto">
      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  </CFooter>
  )
}

export default React.memo(AppFooter)
