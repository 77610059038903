import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/style.scss";
import { generateToken, messaging } from "./notification/firebase";
import { onMessage } from "firebase/messaging";
import FcaBalanceSheet from './layout/FcaBalanceSheetReport';
import CallApis from "./layout/CallApis";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const OpenFcaForm = React.lazy(() => import("./views/pages/openFcaForm/OpenFcaForm"));
const OpenPcrForm = React.lazy(() => import("./views/pages/openPcrForm/OpenPcrForm"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Profile = React.lazy(() => import("./views/pages/profile/Profile"));
const Inspection = React.lazy(() => import("./views/pages/inspection/Inspection"));
const Fca = React.lazy(() => import("./views/pages/fca/Fca"));
const FcaInspection = React.lazy(() => import('./views/pages/fca-inspection'));
const ViewDrawRequest = React.lazy(() => import('./views/pages/drawRequest/View'));
const PrivacyPolicy = React.lazy(() => import('./views/pages/privacy-policy'));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const ConstructionCostReport = React.lazy(() => import('./layout/ConstructionCostReport'));
const HardCostBudgetReport = React.lazy(() => import('./layout/HardCostBudgetReport'));
const PortfolioRecapReport = React.lazy(() => import('./layout/PortfolioRecapReport'));
const BankCheckReport = React.lazy(() => import('./layout/BankCheckReport'));
const BankAccountReport = React.lazy(() => import('./layout/BankAccountReport'));
const ReceiptReport = React.lazy(() => import('./layout/ReceiptReport'));
const InspectionDetailReport = React.lazy(() => import('./layout/InspectionDetailReport'));

class App extends Component {
  componentDidMount() {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
      return toast.success(payload.notification.title);
    });
  }

  render() {
    const { location } = this.props;
    const noApiPaths = ['/login', '/fca/create', '/pcr/create'];

    return (
      <>
        <ToastContainer key="toasts" autoClose={3500} hideProgressBar />
        {!noApiPaths.includes(location.pathname) && <CallApis />}
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="*" element={<DefaultLayout />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/profile" name="Profile Page" element={<Profile />} />
            <Route exact path="/funds-control-account" name="Fca Page" element={<Fca />} />
            <Route exact path="/funds-control-account/:id" name="Fca Page" element={<Fca />} />
            <Route exact path="/fca/inspection" name="Inspection Page" element={<FcaInspection />} />
            <Route exact path="/draw-request" name="View receipt Page" element={<ViewDrawRequest />} />
            <Route exact path="/privacy-policy" name="Privacy" element={<PrivacyPolicy />} />
            <Route exact path="/fca/create" name="OpenFcaForm Page" element={<OpenFcaForm />} />
            <Route exact path="/pcr/create" name="OpenPcrForm Page" element={<OpenPcrForm />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/report/construction-cost" name="View construction report Page" element={<ConstructionCostReport />} />
            <Route exact path="/report/hard-cost-budget" name="View Hard Cost Budget report Page" element={<HardCostBudgetReport />} />
            <Route exact path="/report/portfolio-recap" name="View portfolio recap Page" element={<PortfolioRecapReport />} />
            <Route exact path="/report/bank-check" name="View bank check Page" element={<BankCheckReport />} />
            <Route exact path="/report/bank-account" name="View bank Account Page" element={<BankAccountReport />} />
            <Route exact path="/report/receipt" name="View receipt Page" element={<ReceiptReport />} />
            <Route exact path="/report/inspection-detail" name="View Inspection Report Page" element={<InspectionDetailReport />} />
            <Route exact path="/report/fca-balance-sheet" name="View FC Balance Sheet Page" element={<FcaBalanceSheet />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        </Suspense>
      </>
    );
  }
}

const AppWrapper = () => {
  const location = useLocation();
  return <App location={location} />;
};

export default function WrappedApp() {
  return (
    <BrowserRouter>
      <AppWrapper />
    </BrowserRouter>
  );
}
