export const DRAW_REQUEST_STATUS_DRAFT = 'Draft';
export const DRAW_REQUEST_STATUS_REJECTED = 'Rejected';
export const DRAW_REQUEST_STATUS_SUBMITTED = 'Submitted';
export const DRAW_REQUEST_STATUS_PENDING = 'Pending';
export const DRAW_REQUEST_STATUS_IN_REVIEW = 'In Review';
export const DRAW_REQUEST_STATUS_APPROVED_PENDING_LENDER = 'Approved Pending Lender';

export const PARTY_TYPE_BROKER = 'Broker';
export const PARTY_TYPE_CONTRACTOR = 'Contractor';
export const PARTY_TYPE_INSPECTOR = 'Inspector';
export const PARTY_TYPE_LANDLORD = 'Landlord';
export const PARTY_TYPE_LENDER = 'Lender';
export const PARTY_TYPE_OWNER = 'Owner';
export const PARTY_TYPE_SELLER = 'Seller';
export const PARTY_TYPE_TENANT = 'Tenant';
export const PARTY_TYPE_VENDOR = 'Vendor';
export const PARTY_TYPE_SURETY = 'Surety';

export const drawStatuses = [DRAW_REQUEST_STATUS_DRAFT, DRAW_REQUEST_STATUS_REJECTED, DRAW_REQUEST_STATUS_SUBMITTED, DRAW_REQUEST_STATUS_PENDING, DRAW_REQUEST_STATUS_IN_REVIEW, DRAW_REQUEST_STATUS_APPROVED_PENDING_LENDER, 'Pending Payment', 'Paid', 'Void'];
export const partiesTypes = [PARTY_TYPE_BROKER, PARTY_TYPE_CONTRACTOR, PARTY_TYPE_INSPECTOR, PARTY_TYPE_LANDLORD, PARTY_TYPE_LENDER, PARTY_TYPE_OWNER, PARTY_TYPE_SELLER, PARTY_TYPE_TENANT, PARTY_TYPE_VENDOR, PARTY_TYPE_SURETY];
export const statusTypes = ['Agreement Pending', 'Draft', 'Open', 'Closed', 'Cancelled', 'Suspended'];
export const projectTypes = ['Single Family (One Unit)', 'Single Family (Two Units)', 'Single Family (Two to Four Units)', 'Multi-Family', 'Commercial-Office', 'Commercial-Retail', 'Commercial-Industrial', 'A&D', 'Other'];
export const constructionTypes = ['Ground Up Construction', 'Renovation', 'Addition'];
export const lienWaiverTypes = ['Conditional Final', 'Conditional Progress', 'Unconditional Final', 'Unconditional Progress'];

export const FUNDS_CONTROL_ACCOUNT_STATUS_DRAFT = 'Draft';
export const fcaStatuses = ['Draft', 'Active', 'Closed', 'Cancelled', 'Suspended',];

export const reviewTypes = ['Budget Analysis', 'General Project Analysis', 'Comprehensive Project Analysis'];
export const loanTypes = ['Conventional', 'Private', 'LIHTC', 'SBA',];
export const propertyTypes = ['Residential', 'Commercial', 'Multi-Family', 'Mixed Use', 'LIHTC'];
export const relationTypes = ['Unrelated', 'Owner/Builder', 'Unknown'];
export const sowTypes = ['Addition', 'ADU', 'Core & Shell', 'Core & Shell + Tenant Improvement', 'New Construction', 'Renovation / Remodel', 'Tenant Improvement', 'Other'];
export const pcrProjectTypes = ['A&D: Land & Site Development', 'ADU ', 'Adult Care', 'Building Renovation', 'Church', 'Condo', 'Convenience Store', 'Education', 'Fast Food', 'Gas Station', 'Gas Station/Convenience Store', 'Grocery Store', 'Historical', 'Hospitality', 'Industrial', 'Medical & Dental', 'Mixed-Use', 'Modular', 'Movie Theatre', 'Multi-Family: Garden Style', 'Multi-Family: Urban', 'Office', 'Other', 'Parking Structure', 'Plex', 'Restaurant', 'Retail', 'Single Family: Custom Home', 'Single Family: Track Home', 'Storage', 'Townhome', 'Veterinary', 'Warehouse', 'Warehouse/Office'];

export const SERVICE_TYPE_FUND_CONTROL_NON_CHECKS = 'Fund Control (non-checks)';
export const serviceTypes = [SERVICE_TYPE_FUND_CONTROL_NON_CHECKS, 'Fund Control (checks)', 'Inspection Only', 'PCR Reports'];

export const INSPECTION_STATUS_COMPLETED = 'Completed';

export const validStatusesForRole = (status, partyType) => {
  switch (status) {
    case DRAW_REQUEST_STATUS_DRAFT:
      if (partyType !== 'Contractor') {
        return ['Only contractors can update the draw request.'];
      }
      return [DRAW_REQUEST_STATUS_DRAFT];
    case DRAW_REQUEST_STATUS_PENDING:
      if (partyType !== 'Owner') {
        return ['The draw request is in review by the owner.'];
      }
      return [DRAW_REQUEST_STATUS_PENDING];
    case DRAW_REQUEST_STATUS_APPROVED_PENDING_LENDER:
      if (partyType !== 'Lender') {
        return ['The draw request is in review by the lender.'];
      }
      return [DRAW_REQUEST_STATUS_REJECTED, DRAW_REQUEST_STATUS_APPROVED_PENDING_LENDER];
    default:
      return [];
  }
};

export const retainageAmountOptions = [
  { label: '3.00%', value: '3.00' },
  { label: '5.00%', value: '5.00' },
  { label: '7.00%', value: '7.00' },
  { label: 'No retainage', value: '0.00' }
];

// Permission constants
export const PERMISSION_PUBLIC_FORM_SUBMISSION_LIST = 'Entity:PublicFormSubmission:list';
export const PERMISSION_PUBLIC_FORM_SUBMISSION_CREATE = 'Entity:PublicFormSubmission:create';
export const PERMISSION_FUNDS_CONTROL_ACCOUNT_LIST = 'Entity:FundsControlAccount:list';
export const PERMISSION_FUNDS_CONTROL_ACCOUNT_CREATE = 'Entity:FundsControlAccount:create';
export const PERMISSION_PRE_CONSTRUCTION_REVIEW_LIST = 'Entity:PreConstructionReview:list';
export const PERMISSION_PRE_CONSTRUCTION_REVIEW_CREATE = 'Entity:PreConstructionReview:create';
export const PERMISSION_PARTY_LIST = 'Entity:Party:list';
export const PERMISSION_PARTY_CREATE = 'Entity:Party:create';
export const PERMISSION_DRAW_REQUEST_LIST = 'Entity:DrawRequest:list';
export const PERMISSION_DRAW_REQUEST_CREATE = 'Entity:DrawRequest:create';
export const PERMISSION_RECEIPT_LIST = 'Entity:Receipt:list';
export const PERMISSION_RECEIPT_CREATE = 'Entity:Receipt:create';
export const PERMISSION_BANK_CHECK_LIST = 'Entity:BankCheck:list';
export const PERMISSION_BANK_CHECK_CREATE = 'Entity:BankCheck:create';
export const PERMISSION_INSPECTION_LIST = 'Entity:Inspection:list';
export const PERMISSION_INSPECTION_CREATE = 'Entity:Inspection:create';
export const PERMISSION_BANK_LIST = 'Entity:Bank:list';
export const PERMISSION_BANK_CREATE = 'Entity:Bank:create';
export const PERMISSION_BANK_ACCOUNT_LIST = 'Entity:BankAccount:list';
export const PERMISSION_BANK_ACCOUNT_CREATE = 'Entity:BankAccount:create';
export const PERMISSION_ROLE_PERMISSION_LIST = 'Entity:RolePermission:list';
export const PERMISSION_ROLE_PERMISSION_CREATE = 'Entity:RolePermission:create';
export const PERMISSION_OFFICE_LIST = 'Entity:Office:list';
export const PERMISSION_OFFICE_CREATE = 'Entity:Office:create';
export const PERMISSION_DOCUMENT_LIST = 'Entity:Document:list';
export const PERMISSION_DOCUMENT_CREATE = 'Entity:Document:create';
export const PERMISSION_USER_LIST = 'Entity:User:list';
export const PERMISSION_USER_CREATE = 'Entity:User:create';
export const PERMISSION_REPORT_CONSTRUCTION_COST_VIEW = 'Report:ConstructionCost:view';
export const PERMISSION_REPORT_HARD_CONSTRUCTION_COST_VIEW = 'Report:HardConstructionCost:view';
export const PERMISSION_REPORT_PORTFOLIO_RECAP_VIEW = 'Report:PortfolioRecap:view';
export const PERMISSION_REPORT_BANK_ACCOUNT_VIEW = 'Report:BankAccount:view';
export const PERMISSION_REPORT_BANK_CHECK_VIEW = 'Report:BankCheck:view';
export const PERMISSION_REPORT_INSPECTION_VIEW = 'Report:Inspection:view';
export const PERMISSION_REPORT_FCA_BALANCE_SHEET_VIEW = 'Report:FcaBalanceSheet:view';
export const PERMISSION_REPORT_FCA_TRANSACTION_VIEW = 'Report:FcaTransaction:view';
